import { Home } from '@/components/questionnaire/Home/Home';
// layouts
import MainLayout from '@/layouts/main/MainLayout';
import { getCommonServerSideProps } from '@/utils/getCommonServerSideProps';

const Page = () => {
  return <Home />;
};

Page.getLayout = (page: React.ReactElement) => (
  <MainLayout
    className="main-layout"
    sx={{ padding: 0 }}
  >
    {page}
  </MainLayout>
);

export default Page;
export const getServerSideProps = getCommonServerSideProps([
  'questionnaire',
]);
