import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { IconTitle } from '@/components/discloser/Dashboard/IconTitle';
import { openLinkInNewTab } from '@/utils/url-helper';
import { commonConfig } from '@config';
import { ChevronRight, DocumentScannerOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

export const DataAndInsightsTile = () => {
  const { t } = useTranslation('common', { keyPrefix: 'common.dataAndInsightsTile' });

  const handleRedirectToPortal = useCallback(() => {
    openLinkInNewTab(commonConfig.legacyProducts);
  }, []);

  return (
    <Stack
      bgcolor="background.default"
      p={2}
      gap={2}
    >
      <Box sx={{ display: 'flex' }}>
        <IconTitle
          icon={<DocumentScannerOutlined />}
          label={t('title')}
        />
      </Box>
      <Box
        onClick={handleRedirectToPortal}
        sx={{
          cursor: 'pointer',
          backgroundImage: `url(/images/historical-reports-bg.svg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          paddingY: 6,
          paddingX: 2,
          paddingRight: 18,
          borderRadius: '8px',
          width: '100%',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: 2,
          }}
        >
          <Typography
            variant="h6"
            component="p"
            color="primary.contrastText"
          >
            {t('description')}
          </Typography>
          <ChevronRight sx={{ color: theme => theme.palette.primary.contrastText }} />
        </Box>
      </Box>
    </Stack>
  );
};
